import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { toast } from 'react-toastify';
import React, { memo, useContext, useState } from 'react';
import * as styles from './settings.module.css';
import Button from '../../components/shared/Button';
import Input from '../../components/shared/Input';
import SettingsContext from '../../contexts/SettingsContext';
import UserContext from '../../contexts/UserContext';
import themeConfig from '../../data/themeConfig';
import TopNavbar from '../../components/dashboard/TopNavbar';
import { useDispatch } from '../../contexts/ResumeContext';
import { languages } from '../../i18n';

const Settings = ({ user }) => {
  const { t } = useTranslation();

  const [deleteText, setDeleteText] = useState(
    t('builder.settings.dangerZone.button'),
  );
  const [isDeleteAccountInProgress, setDeleteAccountInProgress] =
    useState(false);

  const dispatch = useDispatch();
  const { deleteAccount } = useContext(UserContext);
  const { theme, setTheme, language, setLanguage } =
    useContext(SettingsContext);

  const handleChangeTheme = (e) => {
    setTheme(e.target.value);
  };

  const handleChangeLanguage = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
    dispatch({ type: 'change_language', payload: lang });
  };

  const handleDeleteAccount = async () => {
    if (deleteText === t('builder.settings.dangerZone.button')) {
      setDeleteText(t('shared.buttons.confirmation'));
      return;
    }

    setDeleteAccountInProgress(true);

    try {
      await deleteAccount();
    } catch (error) {
      toast.error('An error occurred deleting your account.');
      setDeleteAccountInProgress(false);
      setDeleteText(t('builder.settings.dangerZone.button'));
    }
  };

  return (
    <>
      <TopNavbar />
      <section className="mx-auto my-10 xl:w-2/5">
        <h1 className="text-center text-3xl font-bold mt-4">
          Email: {user?.email}
        </h1>

        <Input
          label={t('builder.settings.theme')}
          type="dropdown"
          options={Object.keys(themeConfig)}
          value={theme}
          onChange={handleChangeTheme}
        />

        <label>
          <span>{t('builder.settings.language')}</span>
          <div className="relative grid items-center">
            <select onChange={handleChangeLanguage} value={language}>
              {languages.map((x) => (
                <option key={x.code} value={x.code}>
                  {x.name}
                </option>
              ))}
            </select>

            <FaAngleDown
              size="16px"
              className="absolute right-0 opacity-50 hover:opacity-75 mx-4"
            />
          </div>
        </label>

        <div className={styles.container}>
          <h5>{t('builder.settings.dangerZone.heading')}</h5>

          <p className="leading-loose">
            {t('builder.settings.dangerZone.text')}
          </p>

          <div className="mt-4 flex">
            <Button
              isDelete
              onClick={handleDeleteAccount}
              isLoading={isDeleteAccountInProgress}
            >
              {deleteText}
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default memo(Settings);
